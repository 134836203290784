import React from 'react';
import styles from './LogoMarquee.module.css';
import adaLogo from '../images/ada.png';
import algoLogo from '../images/algo.png';
import arbLogo from '../images/arb.png';
import avaxLogo from '../images/avax.png';
import bnbLogo from '../images/bnb.png';
import btcLogo from '../images/btc.png';
import dogeLogo from '../images/doge.png';
import ethLogo from '../images/eth.png';
import filLogo from '../images/fil.png';
import flrLogo from '../images/flr.png';
import ltcLogo from '../images/ltc.png';
import maticLogo from '../images/matic.png';
import solLogo from '../images/sol.png';
import usdcLogo from '../images/usdc.png';
import usdtLogo from '../images/usdt.png';
import xdcLogo from '../images/xdc.png';
import xlmLogo from '../images/xlm.png';
import xrpLogo from '../images/xrp.png';

const logos = [
  adaLogo, algoLogo, arbLogo, avaxLogo, bnbLogo, btcLogo, dogeLogo, ethLogo,
  filLogo, flrLogo, ltcLogo, maticLogo, solLogo, usdcLogo, usdtLogo, xdcLogo, xlmLogo, xrpLogo
];

const LogoMarquee = () => {
  return (
    <div className={styles.marquee}>
      <div className={styles.track}>
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo ${index}`} key={index} className={styles.logo} />
        ))}
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo duplicate ${index}`} key={'duplicate-' + index} className={styles.logo} />
        ))}
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo triplicate ${index}`} key={'triplicate-' + index} className={styles.logo} />
        ))}
        {logos.map((logo, index) => (
          <img src={logo} alt={`Logo quadruplicate ${index}`} key={'quadruplicate-' + index} className={styles.logo} />
        ))}
      </div>
    </div>
  );
};

export default LogoMarquee;
