import React from "react";
import styles from "./Feature.module.css";

const Feature = ({
  image,
  title,
  description,
  imageSide = "left",
  buttonType,
  buttonText,
  buttonUrl,
  link,
  textlink,
}) => {
  const imageOrderClass =
    imageSide === "right" ? styles.imageRight : styles.imageLeft;
  const buttonClass =
    buttonType === "primary" ? "primaryButton" : "secondaryButton";

  return (
    <section className={`${styles.feature} ${imageOrderClass}`}>
      <div className={`${styles.imageContainer}`}>
        <img src={image} alt={title} className={styles.featureImage} />
      </div>
      <div className={styles.textContentContainer}>
        <div className={styles.textContent}>
          <h2>{title}</h2>
          <p>
            {description}{" "}
            <span>
              <a href={link} target="__blank">
                {textlink}
              </a>
            </span>{" "}
          </p>{" "}
          {buttonText && (
            <center>
              <a href={buttonUrl} className={buttonClass}>
                {buttonText}
              </a>
            </center>
          )}
        </div>
      </div>
    </section>
  );
};

export default Feature;
